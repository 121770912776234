import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FalmedSite2 = () => {
  return (
    <div className="bg-black w-full h-full">
      <StaticImage
        src="../assets/images/falmed-1920-min-v2.webp"
        quality={100}
        alt="Diseño Falmed"
        formats={["webp"]}
        layout="fullWidth"
      />
    </div>
  );
};

export default FalmedSite2;
